import { useContext, useEffect, useRef, useState } from 'react'

import styles from './HeaderLogin.module.scss'

import Link from 'next/link';

import { SessionContext } from '../../../../contexts/SessionContext'
import { DataLayerAPI } from '../../../../api/DataLayerAPI';

export function HeaderLogin() {

  const [showDetails, setShowDetails] = useState(false);

  const dataLayerApi = new DataLayerAPI()

  const handleToggleClick = () => {
    setShowDetails(!showDetails);
  };

  return (
    <SessionContext.Consumer>
      {({ session }) => {

        let links =
            <ul className={styles.loginMenu}>
              <li>
                <Link href='/login' onClick={handleToggleClick}>
                  
                    Log In
                  
                </Link>
              </li>
            </ul>

        if (session?.login_order_id) {
          links =
            <ul className={styles.loginMenu}>
              <li>
                <Link href='/account' onClick={handleToggleClick}>

                  { session.login_order_id }

                </Link>
              </li>
              <li>
                <Link
                  href='/logout'
                  onClick={() => {handleToggleClick(), dataLayerApi.logOutEvent()}}>
                  
                    Log Out
                  
                </Link>
              </li>
            </ul>

        }

        return (
          <div className={styles.login}>
            <a className={styles.toggle} href="#na" onClick={handleToggleClick}>My order</a>

            {showDetails === true && links }

          </div>
        )
      }}

    </SessionContext.Consumer>
  );
}
