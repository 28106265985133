import { CartType, CartTicket, AddToCartTicketType } from '../types/CartType'

import { Updater } from 'use-immer'

import { httpRequest } from './httpRequest'

import { DataLayerAPI } from './DataLayerAPI'
import { HotelType, RoomType } from '../types/HotelType';
import { GoogleDataLayerAPI } from './GoogleDataLayerAPI';

const dataLayerApi = new DataLayerAPI()
const googleDataLayerApi = new GoogleDataLayerAPI()

export class CartAPI {

  constructor(private apiBaseUrl: string, private updateCart: Updater<CartType>) { }


  /** Get latest cart contents from API and update state. */
  public async get() {

    try {
      const cart: CartType = await this.getCart()
      cart.loading = false
      this.updateCart(cart)

    } catch (err) {

      this.updateCart((draft: CartType) => { draft.loading = false })
      console.warn(err)
    }
  }


  private async getCart(): Promise<CartType> {
    const result = await fetch(`${this.apiBaseUrl}/cart`, { credentials: 'include' })
    const cart: CartType = await result.json()
    return cart
  }


  /** Add items to cart and update state with latest details. */
  public async add(tickets: Array<AddToCartTicketType>) {

    this.updateCart((draft: CartType) => { draft.loading = true })

    let cart: CartType | null = null

    const addedTickets: Array<{ ticket: CartTicket, qty: number}> = []

    // Add tickets sequentially rather than concurrently, to ensure result of final call
    // contains updates from earlier calls.
    for (const ticket of tickets) {

      if (!ticket.qty) continue;

      const body = {
        items: [
          {
            ticket_id: ticket.ticketId,
            date_id: ticket.dateId,
            qty: ticket.qty,
          }
        ],
      }

      try {

        const result = await httpRequest('POST', `${this.apiBaseUrl}/cart?returnGet=1`, body)

        cart = await result.json()
        const addedTicket = cart?.data.tickets.find(t => t.ticket_id === ticket.ticketId && t.data.date_id === ticket.dateId)
        if (addedTicket) {
          addedTickets.push({ ticket: addedTicket, qty: ticket.qty })
        } else {
          console.warn("Unable to find details of ticket added to cart to add to data layer.")
        }

      } catch (err) {

        this.updateCart((draft: CartType) => { draft.loading = false })
        console.warn(err)
      }
    }

    if (addedTickets.length) {
      // Adobe data layer:
      dataLayerApi.logAddToCart(addedTickets)
      // Google data layer:
      googleDataLayerApi.logAddToCart(addedTickets)
    }

    if (cart) {
      cart.loading = false
      this.updateCart(cart)
    } else {
      this.updateCart((draft: CartType) => { draft.loading = false })
    }
  }

  public async addRoom(room:RoomType, roomNumber:number, hotel:HotelType, numAdults:number, numChildren:number, numInfants:number, childAges:number[], dateFrom:string, dateTo:string) {

    this.updateCart((draft: CartType) => { draft.loading = true })

    let cart: CartType | null = null

      if (+room.price < 0) return;

      const roomId: string = Object.keys(room.packages)[0]
      const hotelRoom = room.packages[roomId]

      const body = {
          items: [
            {
              ticket_id: hotelRoom.id,
              title: hotelRoom.title,
              qty: 1,
              tags: [
                "rooms",
                `room-${roomNumber}`,
                "hotels"
              ],
              attributes: {
                5: [
                  dateFrom
                ],
                24: [
                  childAges
                ],
                42: [
                  dateTo
                ],
                48: [
                  numAdults
                ],
                49: [
                  numChildren
                ],
                87: [
                  numInfants
                ]
              },
              date_id: "",
              data: {
                attraction_id: hotel.id,
                attraction_title: hotel.title,
                category: hotel.category,
                resort_area: hotel.resort_area,
                tickets_required: 0,
                price: room.price,
                price_includes: "room",
                room: room,
                tickets_available: 0,
                dining_available: 0,
                default_tickets_total: 0,
                hotel_tax: "0.00"
              }
            }
          ]
        }

      try {

        const result = await httpRequest('POST', `${this.apiBaseUrl}/cart?returnGet=1`, body)

        cart = await result.json()
        const addedTicket = cart?.data.tickets.find((t) => t.ticket_id === hotelRoom.id)
        if (addedTicket) {
          // Adobe data layer:
          dataLayerApi.logAddToCart([{ ticket: addedTicket, qty: 1}])
          // Google data layer
          googleDataLayerApi.logAddToCart([{ ticket: addedTicket, qty: 1 }])
        } else {
          console.warn("Unable to find details of ticket added to cart to add to data layer.")
        }

      } catch (err) {

        this.updateCart((draft: CartType) => { draft.loading = false })
        console.warn(err)
      }


    if (cart) {
      cart.loading = false
      this.updateCart(cart)
    } else {
      this.updateCart((draft: CartType) => { draft.loading = false })
    }
  }

  /** Update the quantity of the given item. */
  public async setQty(cartItemId: string, newQty: number) {

    this.updateCart((draft: CartType) => { draft.loading = true })

    if (cartItemId) {

      const body = {
        items: [
          {
            cart_item_id: cartItemId,
            qty: newQty
          }
        ]
      }

      try {

        let cart: CartType = await this.getCart()

        const cartItem = cart?.data?.tickets.find(t => t.cart_item_id === cartItemId)
        const oldQty = cartItem?.qty

        const result = await httpRequest('PUT', `${this.apiBaseUrl}/cart?returnGet=1`, body)

        cart = await result.json()
        cart.loading = false
        this.updateCart(cart)

        if (cartItem && oldQty) {
          if (oldQty > newQty) {
            dataLayerApi.logRemoveFromCart([{ ticket: cartItem, qty: oldQty - newQty}])
          } else if (newQty > oldQty) {
            dataLayerApi.logAddToCart([{ ticket: cartItem, qty: newQty - oldQty}])
          }
        }

      } catch (err) {

        this.updateCart((draft: CartType) => { draft.loading = false })
        console.warn(err)
      }

    } else {

      this.updateCart((draft: CartType) => { draft.loading = false })
    }
  }


  /** Remove a room from the cart. */
  public async removeRoom(roomNumber:number) {

    this.updateCart((draft: CartType) => { draft.loading = true })

    const cart = await this.getCart()

    let ticketId: string | undefined = undefined

    if (cart.data.tickets[+roomNumber-1].ticket_id !== undefined) {
      ticketId = cart.data.tickets[+roomNumber-1].ticket_id
    }

    if (!ticketId) return this.clear()

    const cartItem = cart.data.tickets.find(t => t.ticket_id === ticketId)
    const tagItem = cart.data.tickets.filter(t => t.tags.includes(`room-${roomNumber}`))

    if (cartItem && tagItem) {

      try {

        const result = await httpRequest('DELETE', `${this.apiBaseUrl}/cart?tag=room-${roomNumber}`)

        const cart = await result.json()
        cart.loading = false
        this.updateCart(cart)

        dataLayerApi.logRemoveFromCart([{ ticket: cartItem, qty: cartItem.qty}])

      } catch (err) {

        this.updateCart((draft: CartType) => { draft.loading = false })
        console.warn(err)
      }

    } else {
        this.updateCart((draft: CartType) => { draft.loading = false })
    }
  }

  /** Remove item from cart and update state with latest details. */
  public async remove(ticketId: string, dateId: string | null, qty: number) {

    this.updateCart((draft: CartType) => { draft.loading = true })

    const cart: CartType = await this.getCart()

    // @todo: Get this working once we start working on the dated tickets:
    dateId = ''

    const cartItem = cart.data.tickets.find(t => t.ticket_id === ticketId && (t.data.date_id ?? '' == dateId))

    if (cartItem) {

      const body = { qty }

      try {

        const result = await httpRequest('DELETE', `${this.apiBaseUrl}/cart/${cartItem.cart_item_id}?returnGet=1`, body)

        const cart: CartType = await result.json()
        cart.loading = false
        this.updateCart(cart)

        // Log remove event in data layer:
        dataLayerApi.logRemoveFromCart([{ ticket: cartItem, qty }])

      } catch (err) {

        this.updateCart((draft: CartType) => { draft.loading = false })
        console.warn(err)
      }

    } else {

      this.updateCart((draft: CartType) => { draft.loading = false })
    }

  }


  /** Delete all of the given ticket ids from the cart. */
  public async removeAll(ticketIds: Array<string>, dateOrYearStr?: string) {

    this.updateCart((draft: CartType) => { draft.loading = true })

    const cart = await this.getCart()

    // Identify the cart items to remove, based on ticket id and (if given) date:
    let cartTicketsToRemove: Array<CartTicket> = cart.data.tickets.filter(t => ticketIds.includes(t.ticket_id))
    if (dateOrYearStr) {
      cartTicketsToRemove = cartTicketsToRemove.filter(t => t.date === dateOrYearStr || t.year === dateOrYearStr)
    }

    let newCart: CartType | null = null

    const removedTickets: Array<{ ticket: CartTicket, qty: number}> = []

    // Remove tickets sequentially rather than concurrently, to ensure result of final call
    // contains updates from earlier calls.
    for (const cartTicket of cartTicketsToRemove) {
      const url = `${this.apiBaseUrl}/cart/${cartTicket.cart_item_id}?returnGet=1`
      const result = await httpRequest('DELETE', url)
      newCart = await result.json()

      removedTickets.push({ ticket: cartTicket, qty: cartTicket.qty})
    }

    // Log remove event in data layer:
    dataLayerApi.logRemoveFromCart(removedTickets)

    if (newCart) {
      newCart.loading = false
      this.updateCart(newCart)
    } else {
      this.updateCart((draft: CartType) => { draft.loading = false })
    }
  }


  /** Clear cart contents and update state. */
  public async clear() {

    try {

      const result = await httpRequest('DELETE', `${this.apiBaseUrl}/cart?returnGet=1`)
      const cart: CartType = await result.json()
      cart.loading = false
      this.updateCart(cart)

    } catch (err) {

      this.updateCart((draft: CartType) => { draft.loading = false })
      console.warn(err)
    }
  }


}
